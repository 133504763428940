const url = {
    // dev: {
    //     publicUrl: 'https://www.quantoukeji.com/api/',
    //     manageUrl: 'https://www.quantoukeji.com/api/',
    // },
    // pro: {
    //     publicUrl: 'https://www.quantoukeji.com/api/',
    //     manageUrl: 'https://www.quantoukeji.com/api/',
    // }
    
    dev: {
        publicUrl: 'https://www.quantoukeji.com/api/',
        manageUrl: 'https://www.quantoukeji.com/api/',
    },
    pro: {
        publicUrl: 'https://www.quantoukeji.com/api/',
        manageUrl: 'https://www.quantoukeji.com/api/',
    }
}

export const get = () => {
    if(process.env.NODE_ENV === 'production'){
        return url['pro']
    }else{
        return url['dev']
    }
}
