<template>
  <div class="login">
    <h3>拳头小侠共享充电宝管理平台</h3>
    <el-input placeholder="账户" prefix-icon="el-icon-user" v-model="params.Data1" class="input"  @keypress.native.enter="onEnterPress"></el-input>
    <el-input placeholder="密码" prefix-icon="el-icon-lock" v-model="params.Data2" class="input" @keypress.native.enter="onEnterPress" show-password></el-input>
    <el-button class="btn" @click="login">登录</el-button>
    <p>Copyright &copy; 2023拳头小侠出品  <a style="color:blue" href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">浙ICP备2023034830号-1</a></p>
  </div>
</template>

<script>
import { login } from "@/api/public.js";
export default {
  data() {
    return {
      params:{
        Data1:'', // 账号
        Data2:'', // 密码
      }
    };
  },
  mounted(){},
  components: {},

  computed: {},

  methods: {
    // 回车键登录
    onEnterPress(e){
      if(e.keyCode===13){
        this.login();
      }
    },
    // 点击登录按钮登录
    login(){
      login(this.params)
       .then((res)=>{
         if(res.success){
           this.$emit('goHome',true);
           sessionStorage.setItem('userInfo',JSON.stringify(res.data.user));
           sessionStorage.setItem('token',res.data.token);
         }else{
           this.$message({
              showClose: true,
              message: res.message,
              type: 'error'
           });
         }
       })
    }
  }
};
</script>
<style lang="scss" scoped>
  .login {
    background: #001529;
    width: 100vw;
    height: 100vh;
    // padding:100px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    h3{
      margin-top: 5%;
      font-size: 36px;
      color:white;
      font-weight: bolder;
      letter-spacing: 6px;
      margin-bottom: 20px;
    }
    .input {
      width: 400px;
      margin-top: 30px;
      display: block;
    }
    .btn {
      background: #1890ff;
      width: 400px;
      margin-top: 30px;
      color: #fff;
    }
    p {
      position: fixed;
      bottom:20px;
      font-size: 14px;
      color: #ccc;
    }
  }
</style>
