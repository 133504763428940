import Vue from 'vue'
import Vuex from 'vuex';
import VueRouter, { RouteConfig } from 'vue-router'
import Store from "@/store/index"
import {Watch} from "vue-property-decorator";

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.replace
VueRouter.prototype.push = function replace(location) {
  return originalPush.call(this, location).catch(err => err)
}
// @ts-ignore
let menu_list = Store.state.menu.menuArr
// @ts-ignore
@Watch(Store.state.menu.menuArr)
function getMenu(oldValue,newValue){}

let routes: Array<RouteConfig> = [
  {
    path:'/equipManage/goodsList', // 设备售卖商品列表
    name:'GoodsList',
    component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/equipList/goodsList.vue'),
  },
  {
    path:'/trading/orderDetail', // 订单详情
    name:'orderDetail',
    component: () => import(/* webpackChunkName: "about" */ '@/views/tradingManage/orderList/detail.vue'),
  },
  {
    path:'/equipManage/replenishmentGoods', // 补货商品
    name:'replenishmentGoods',
    component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/replenishmentRecord/detail.vue'),
  },
  {
    path:'/replenishment/operationRecords', // 补货管理操作记录
    name:'replenishmentGoods',
    component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/replenishmentRecord/operationRecord.vue'),
  },
  {
    path:'/equipManage/inventoryGoods', // 盘存商品
    name:'inventoryGoods',
    component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/inventoryRecords/detail.vue'),
  },
].concat(menu_list);
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// router.beforeEach((to,from,next)=>{
//   const type=sessionStorage.getItem('centerType')
//   if(type){
//     Store.commit('menu/changeMenuData',type);
//   }
//   next();
// })

export default router;
