export const operateMenu=[
    { // 首页
      path: '/',
      name: 'Home',
      name_cn:'首页',
      icon: 'el-icon-s-marketing',
      component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
    },
    { // 设备列表
        path: '/equipManage',
        name: 'EquipManage',
        name_cn:'设备管理',
        icon: 'el-icon-setting',
        component: {render: (e) => e("router-view")},
        children:[{
            path: '/equipManage/equipList',
            name: 'EquipList',
            // @ts-ignore
            name_cn:'设备列表',
            component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/equipList/index.vue'),
        },
        {
          path: '/equipManage/cdbList',
          name: 'cdbList',
          // @ts-ignore
          name_cn:'充电宝列表',
          component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/cdbList/index.vue'),
        },]
    },
    { // 交易管理
      path: '/trading',
      name: 'Trading',
      name_cn:'交易管理',
      icon: 'el-icon-sell',
      component: {render: (e) => e("router-view")},
      children:[{
          path: '/trading/orderList',
          name: 'OrderList',
          // @ts-ignore
          name_cn:'交易订单',
          component: () => import(/* webpackChunkName: "about" */ '@/views/tradingManage/orderList/index.vue'),
      },{
        path: '/trading/abnormalOrder',
        name: 'AbnormalOrder',
        // @ts-ignore
        name_cn:'异常订单',
        component: () => import(/* webpackChunkName: "about" */ '@/views/tradingManage/abnormalOrder/index.vue'),
      },{
        path: '/trading/refundOrder',
        name: 'RefundOrder',
        // @ts-ignore
        name_cn:'退款订单',
        component: () => import(/* webpackChunkName: "about" */ '@/views/tradingManage/refund/index.vue'),
      },{
        path: '/trading/templateList',
        name: 'TemplateList',
        // @ts-ignore
        name_cn:'消费模板',
        component: () => import(/* webpackChunkName: "about" */ '@/views/template/templateList/index.vue'),
      },{
        path: '/trading/benefit',
        name: 'benefitList',
        // @ts-ignore
        name_cn:'分润列表',
        component: () => import(/* webpackChunkName: "about" */ '@/views/tradingManage/benefit/index.vue'),
      },{
        path: '/trading/withdrawalAccount',
        name: 'withdrawalAccount',
        // @ts-ignore
        name_cn:'提现账号',
        component: () => import(/* webpackChunkName: "about" */ '@/views/tradingManage/withdrawalAccount/index.vue'),
      },{
        path: '/trading/withdrawalRecords',
        name: 'withdrawalRecords',
        name_cn:'提现记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/tradingManage/withdrawalRecords/index.vue'),
      },{
        path: '/trading/invoiceTitle',
        name: 'invoiceTitle',
        name_cn:'开票信息',
        component: () => import(/* webpackChunkName: "about" */ '@/views/tradingManage/invoiceTitle/index.vue'),
      }]
    },
    { // 计费模板管理
      path: '/template',
      name: 'Template',
      name_cn:'计费模板管理',
      icon: 'el-icon-box',
      component: {render: (e) => e("router-view")},
      children:[
        {
          path: '/template/templateList',
          name: 'TemplateList',
          // @ts-ignore
          name_cn:'计费模板列表',
          component: () => import(/* webpackChunkName: "about" */ '@/views/template/templateList/index.vue'),
        }
      ]
    },
    { // 场所管理
      path: '/place',
      name: 'Place',
      name_cn:'场所管理',
      icon: 'el-icon-office-building',
      component: {render: (e) => e("router-view")},
      children:[{
          path: '/place/placeList',
          name: 'PlaceList',
          // @ts-ignore
          name_cn:'场所列表',
          component: () => import(/* webpackChunkName: "about" */ '@/views/placeManage/placeList/index.vue'),
      },
      ]
    },
    { // 小程序用户管理
      path: '/miniUserManage',
      name: 'MiniUserManage',
      name_cn:'小程序用户管理',
      icon: 'el-icon-user',
      component: {render: (e) => e("router-view")},
      children:[{
          path: '/miniUserManage/wechatUserList',
          name: 'WechatUserList',
          // @ts-ignore
          name_cn:'微信用户列表',
          component: () => import(/* webpackChunkName: "about" */ '@/views/miniUserManage/wechatUserList/index.vue'),
      },{
        path: '/miniUserManage/aliUserList',
        name: 'AliUserList',
        // @ts-ignore
        name_cn:'支付宝用户列表',
        component: () => import(/* webpackChunkName: "about" */ '@/views/miniUserManage/aliUserList/index.vue'),
      }]
    },
    { // 通讯记录
      path: '/communicationManage',
      name: 'CommunicationManage',
      name_cn:'通讯管理',
      icon: 'el-icon-data-line',
      component: {render: (e) => e("router-view")},
      children:[{
          path: '/communicationManage/communicationRecords',
          name: 'CommunicationRecords',
          // @ts-ignore
          name_cn:'通讯记录',
          component: () => import(/* webpackChunkName: "about" */ '@/views/communicationManage/communicationRecords/index.vue'),
      },
      ]
    },
    { // 权限管理
      path: '/rights',
      name: 'Rights',
      name_cn:'权限管理',
      icon: 'el-icon-lock',
      component: {render: (e) => e("router-view")},
      children:[{
          path: '/rights/menu',
          name: 'Menu',
          // @ts-ignore
          name_cn:'菜单管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/rights/menu/index.vue'),
        },{
          path: '/rights/user',
          name: 'rightsUser',
          // @ts-ignore
          name_cn:'用户管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/rights/user/index.vue'),
        },{
          path: '/rights/rightsManege',
          name: 'RightsManege',
          // @ts-ignore
          name_cn:'权限分配',
          component: () => import(/* webpackChunkName: "about" */ '@/views/rights/rightsManege/index.vue'),
        },{
          path: '/rights/roleManege',
          name: 'RoleManege',
          // @ts-ignore
          name_cn:'角色管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/rights/roles/index.vue'),
        },{
          path: '/rights/dictionary',
          name: 'Dictionary',
          // @ts-ignore
          name_cn:'字典管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/rights/dictionary/index.vue'),
        },]
    },
];