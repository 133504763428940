import router from '@/router/index';
import {operateMenu} from '@/router/menuRouter/operateMenu.js';
const state = {
    menuArr:operateMenu,
}

const mutations = {
    add: (state:any,view: any)=> {
        state.menuArr.push(view)
    },
    delete: (state: any, view: any)=> {
        let index = state.menuArr.indexOf(view)
        if (index > -1) {
            state.menuArr.splice(index, 1)
        }
    },
    set: (state: any, view: any)=> {
        state.menuArr = view
        let menu_list = []
        view.forEach((value,key)=>{
            let children = [];
            if(value['children'].length>0){
                value['children'].forEach((v,k)=>{
                    children.push({
                        path: v['path'],
                        name: v['name'],
                        name_cn:v['name_cn'],
                        icon: v['icon'],
                        component:() => import(/* webpackChunkName: "about" */ v['comp_path']),
                        children:[]
                    })
                })
            }                                                                                                                                                                                                                                                                                                                                   
            menu_list.push({
                path: value['path'],
                name: value['name'],
                name_cn:value['name_cn'],
                icon: value['icon'],
                component:() => import(/* webpackChunkName: "about" */ value['comp_path']),
                children:children
            })
        })
        router.options.routes = menu_list
    },
    changeMenuData:(state: any, view: any)=>{
        if(!sessionStorage.getItem('centerType')){
            sessionStorage.setItem('centerType',view);
            window.location.reload();
        }else{
            // router.options.routes=state.menuArr;
            sessionStorage.removeItem('centerType');
        }
        // sessionStorage.setItem('menuArr',JSON.stringify(state.menuArr));
    }
}

export default {
    namespaced: true,  // 这个是命名空间，用来区分不同的模块，我是根据文件的名字来区分
    state,
    mutations,
};
